import pick from 'lodash/pick';

export const UserGroup = {
  GROUP_ADMIN: 'group_admin',
  PROJECT_ADMIN: `${process.env.PROJECT_GROUP}_ADMIN`
};

class User {
  id: string;
  emailVerified: boolean;
  email: string;
  groups: string[];

  data() {
    return pick(this, ['id', 'email', 'emailVerified', 'groups']);
  }

  inGroup(group: UserGroup) {
    return this.groups.includes(group);
  }

  constructor(props: Pick<User, 'id' | 'email' | 'emailVerified' | 'groups'>) {
    Object.assign(this, props);
  }
}

export default User;
